<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <component
    :is="tag"
    :class="componentClasses"
    :type="type"
    @click="onButtonClick"
  >
    <slot name="before" />
    <i
      v-if="isLoading"
      class="bx bx-loader-alt bx-spin"
    />
    <i
      v-else-if="iconName"
      :class="[
        iconColor,
        iconName ? 'bx ' + iconName : '',
        { 'uikit-button__icon_right': hasRightIcon },
      ]"
    />
    <span
      v-if="title"
      class="uikit-button__title"
    >
      {{ buttonTitle }}
    </span>
    <default-badge
      v-if="counter"
      class="uikit-button__counter"
      :count="counter"
      :color="counterColor"
      :size="size === 'l' ? 'm' : 's'"
    />
  </component>
</template>
<script>
  import DefaultBadge from '@/components/base/uiKit/DefaultBadge';

  export default {
    name: 'DefaultButton',
    components: {
      DefaultBadge,
    },
    props: {
      counter: {
        type: [String, Number],
        default: 0,
      },
      counterColor: {
        type: String,
        default: 'brand',
      },
      tag: {
        type: String,
        default: 'button',
      },
      type: {
        // button, submit, reset
        type: String,
        default: 'button',
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
        default: '',
      },
      loadingTitle: {
        type: String,
        default: '',
      },
      form: {
        // square, circle, rectangle, ellipse
        type: String,
        default: 'square',
      },
      size: {
        // в зависимости от формы: смотри css
        type: String,
        default: 'm',
      },
      color: {
        // primary, neutral, transparent, success, danger, black
        type: String,
        default: '', // !!! не ставить значение, иначе кастомные стили не встанут
      },
      iconName: {
        // box-icons
        type: String,
        default: '',
      },
      hasRightIcon: {
        type: Boolean,
        default: false,
      },
      isFulled: {
        // width: 100%
        type: Boolean,
        default: false,
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
      hasPaddings: {
        type: Boolean,
        default: true,
      },
      titleColor: {
        // для нетиповой стилизации
        type: String,
        default: '',
      },
      iconColor: {
        // для нетиповой стилизации
        type: String,
        default: '',
      },
      hoveredTitleColor: {
        // для нетиповой стилизации
        type: String,
        default: '',
      },
      backgroundColor: {
        // для нетиповой стилизации
        type: String,
        default: '',
      },
      hoveredBackgroundColor: {
        // для нетиповой стилизации
        type: String,
        default: '',
      },
      disabledBackgroundColor: {
        // для нетиповой стилизации
        type: String,
        default: '',
      },
      borderColor: {
        // для нетиповой стилизации
        type: String,
        default: '',
      },
      pointerEvents: {
        // для нетиповой стилизации
        type: Boolean,
        default: true,
      },
      notyMessageOnDisabled: {
        type: String,
        default: '',
      },
    },
    computed: {
      buttonTitle () {
        if (this.isLoading) {
          return this.loadingTitle || this.title;
        } else {
          return this.title;
        }
      },
      componentClasses () {
        return [
          this.titleColor ? this.titleColor : '',
          'uikit-button',
          this.color ? 'uikit-button_color_' + this.color : '',
          this.form ? 'uikit-button_form_' + this.form : '',
          this.size ? 'uikit-button_size_' + this.form + '_' + this.size : '',
          { 'uikit-button_fulled': this.isFulled },
          { 'uikit-button_disabled': this.isDisabled },
          { 'uikit-button_without-paddings': !this.hasPaddings },
          this.titleColor,
          this.hoveredTitleColor,
          this.backgroundColor,
          this.hoveredBackgroundColor,
          this.disabledBackgroundColor,
          this.borderColor ? 'uikit-button_border_' + this.borderColor : '',
          !this.pointerEvents ? 'uikit-button_pointer-none' : '',
        ];
      },
    },
    methods: {
      onButtonClick () {
        if (this.isDisabled && this.notyMessageOnDisabled) {
          this.$noty.error(this.notyMessageOnDisabled);
        }

        if (this.isDisabled) return;

        this.$emit('action');
      },
    },
  };
</script>

<style lang="scss">
.uikit-button {
  @include default-button;
  position: relative;
  gap: 4px;
  width: 100%
  &_pointer-none {
    pointer-events: none;
  }
  &_size {
    &_rectangle {
      display: flex;
      align-items: center;

      &_l {
        gap: 8px;
        padding: 0px 8px;
        height: 40px;
        min-width: 40px;

        i {
          font-size: 24px;
        }

        span {
          @include text_m;
        }
      }

      &_m {
        padding: 0 12px;
        min-width: 32px;
        min-height: 32px;

        span {
          @include text_s;
        }
      }

      &_s {
        gap: 8px;
        padding: 0px 8px;
        height: 24px;
        min-width: 40px;

        span {
          @include text_xs;
        }
      }

      &_xs {
        gap: 4px;
        padding: 0px 8px;
        height: 16px;

        span {
          @include text_xs;
        }
      }

      &_l {
        i {
          font-size: 24px;
        }
      }

      &_s,
      &_m {
        i {
          font-size: 16px;
        }
      }
    }
    &_circle {
      &_l {
        height: 40px;
        width: 40px;
        min-width: 40px;

        i {
          font-size: 20px;
        }

        span {
          @include text_m;
        }
      }

      &_m {
        height: 32px;
        width: 32px;
        min-width: 32px;

        span {
          @include text_s;
        }
      }

      &_s {
        height: 24px;
        width: 24px;
        min-width: 24px;

        span {
          @include text_xs;
        }
      }
      &_s,
      &_m {
        i {
          font-size: 16px;
        }
      }
    }
    &_square,
    &_ellipse {
      &_l {
        height: 40px;
        min-width: 40px;
        padding: 0 8px;

        i {
          font-size: 24px;
        }

        span {
          @include text_m;
        }
      }

      &_m {
        height: 32px;
        min-width: 32px;
        padding: 0 8px;
        span {
          @include text_s;
        }
      }

      &_s {
        height: 24px;
        min-width: 24px;
        padding: 0 8px;
        span {
          @include text_xs;
        }
      }

      &_xs {
        gap: 4px;
        padding: 0px 8px;

        span {
          @include text_xs;
        }
      }

      &_s, &_m {
        i {
          font-size: 16px;
        }
      }
    }
  }

  &_form {
    &_square,
    &_rectangle {
      border-radius: 4px;
    }

    &_circle {
      border-radius: 50%;
    }

    &_ellipse {
      border-radius: 100px;
    }
  }

  &__icon {
    &_right {
      order: 2;
    }
  }

  &_fulled {
    width: 100%;
  }

  &_without-paddings {
    padding: 0 !important;
  }

  &_color {
    &_primary {
      color: $t-light-white;
      background: var(--primary-cl);

      &:hover:not(:disabled) {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), var(--primary-cl);
      }
    }

    &_black {
      color: $t-light-white;
      background: #040d14;

      &:hover:not(:disabled) {
        background:
          linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)),
          var(--primary-cl);
      }
    }

    &_neutral {
      color: var(--color-secondary);
      background: var(--neutral-100);

      &:hover:not(:disabled) {
        background: var(--neutral-200);
      }
    }

    &_transparent-gr {
      color: var(--color-secondary);

      &:hover:not(:disabled) {
        background: var(--neutral-200);
      }
    }

    &_ai-purple-bg {
      color: #9a49fc;
      background: var(--magic-gradient-subdued);

      &:hover:not(:disabled) {
        opacity: 0.7;
      }
    }

    &_transparent {
      color: var(--primary-cl);

      &:hover:not(:disabled) {
        color: $cl-brand-hover;
      }
    }

    &_danger {
      color: $cl-danger;
      background: $cl-danger-bg;

      &:hover:not(:disabled) {
        background: $cl-danger-hover;
      }
    }

    &_success {
      color: $cl-success;
      background: $cl-success-bg;

      &:hover:not(:disabled) {
        background: $cl-success-hover;
      }
    }

    &_warning {
      color: $cl-warning;
      background: $cl-warning-bg;

      &:hover:not(:disabled) {
        background: $cl-warning-hover;
      }
    }

    &_tertiary {
      color: var(--color-secondary);
      background: var(--neutral-200);

      &:hover:not(:disabled) {
        background: var(--neutral-300);
      }
    }

    &_info {
      color: $cl-info;
      background: $cl-info-bg;

      &:hover:not(:disabled) {
        background: $cl-info-hover;
      }
    }

    &_ai-gradiaent {
      color: var(--btn-bg);
      background: var(--magic-button-bg-gradient);

      &:hover:not(:disabled) {
        opacity: 0.7;
      };
    }
  }

  &_border {
    &_success {
      border: 1px solid $cl-success;
      &_05 {
        border: 0.5px solid $cl-success;
      }
    }
    &_danger {
      border: 1px solid $cl-danger;
      &_05 {
        border: 0.5px solid $cl-danger;
      }
    }
    &_primary {
      border: 1px solid var(--primary-cl);
      &_05 {
        border: 0.5px solid var(--primary-cl);
      }
    }
    &_neutral-300 {
      border: 1px solid var(--neutral-300);
      &_05 {
        border: 0.5px solid var(--neutral-300);
      }
    }
    &_tertiary {
      border: 1px solid var(--color-text-tetriary);
      &_05 {
        border: 0.5px solid var(--color-text-tetriary);
      }
    }
  }

  &__title {
    white-space: nowrap;
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__counter {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(37.5%, -25%);
  }
}
</style>
