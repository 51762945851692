import { defaultFilters } from '@/data/defaulFilters.js';
import { mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import { setWorkflowFilters } from '@/use/setWorkflowFilters';
import { AiLabelsNames } from '@/data/ai/labels';

export default {
  computed: {
    ...mapGetters({
      workflowsList: 'workflows/workflowsList',
      labelsList: 'labels/labelsList',
      aiLabelsList: 'labels/aiLabelsList',
      user: 'user/user',
    }),
    isOttomateyBrand () {
      return this.user.config.brand === 'ottomatey';
    },
  },
  methods: {
    getFiltersData () {
      const result = [];
      const labelsCategory = {
        category: {
          name: this.$tc('common.label', 2),
          _id: 'labels',
          type: 'labels',
          icon: 'bx-label',
        },
        items: this.labelsList,
      };
      const autoLabelsCategory = {
        category: {
          name: this.$tc('common.autoLabel', 2),
          _id: 'ai_labels',
          type: 'labels',
          icon: 'bxs-magic-wand',
          label: {
            name: 'AI-powered',
            type: 'magic',
          },
        },
        items: this.aiLabelsList.map((label) => ({
          ...label,
          name: AiLabelsNames.value[label.name],
        })),
      };
      const statisticsCategory = {
        category: {
          name: this.$t('common.statistics'),
          _id: 'statistics',
          type: 'statistics',
          icon: 'bx-bar-chart',
        },
        items: [
          {
            _id: 'with_opened_email',
            name: this.$t('statistics.emailOpenRate'),
          },
          {
            _id: 'with_bounced_email',
            name: this.$t('statistics.bounceRate'),
          },
        ],
      };
      const campaignsCategory = {
        category: {
          name: this.$tc('common.campaign', 2),
          _id: 'workflows',
          type: 'workflows',
          icon: 'bx-briefcase',
        },
        items: setWorkflowFilters(this.$isDevSpace || this.$isBuzzBrand || this.isOttomateyBrand),
      };
      const defaultCategories = cloneDeep(defaultFilters.value);
      return result.concat(
        campaignsCategory,
        statisticsCategory,
        defaultCategories,
        labelsCategory,
        autoLabelsCategory
      );
    },
  },
};
