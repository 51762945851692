/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import { getUser } from '@/api/userMethods';
import { getSpace, getSubscribe } from '@/api/spaceMethods';
import { redirectToSetSpaceMixin } from '@/mixins/redirectToSetSpaceMixin';
import { mapGetters } from 'vuex';
import store from '@/store';
import { redirectRules } from '@/data/redirectRules';
import { setBrandScripts } from '@/utils/externalScripts';
import { setServiceBell } from '@/utils/externalScripts/serviceBell';
import { setChatra } from '@/utils/externalScripts/setChatra';
import { setTawk } from '@/utils/externalScripts/setTawk';
import { setHubSpot } from '@/utils/externalScripts/setHubSpot';
import { impactTrackScript } from '@/utils/externalScripts/setTagScript';
import { sleep } from '@/utils/global/utils';
import { tierFeatures } from '@/data/tiersFeatures';

store.commit('mobile/SET_MOBILE_CHECK');
const isMobile = store.getters['mobile/isMobile'];

export const firstFetchMixin = {
  mixins: [redirectToSetSpaceMixin],
  computed: {
    ...mapGetters({
      credentialsList: 'credentials/credentialsList',
      selectedCredential: 'credentials/selectedCredential',
      isAdmin: 'account/isAdmin',
      user: 'user/user',
      account: 'account/account',
      space: 'account/space',
      theme: 'theme',
      isHorizontal: 'view/horizontal',
    }),
  },
  data () {
    return {
      isRedirected: false,
      beforeRoute: null,
      showVendastaBillingEnabledModal: false,
      _redirect: null,
    };
  },
  beforeRouteEnter (to, from, next) {
    next(vm => (vm.beforeRoute = from));
  },
  methods: {
    _setSupport (user, account, theme) {
      const { support } = user.config;
      const supportName = support?.name;
      const supportId = support?.app_id;
      const supportUserId = support?.user_id;
      if (!supportName) {
        return;
      }
      try {
        switch (supportName) {
          case 'no_support':
            break;
          case 'tawk':
            setTawk(supportId, supportUserId);
            break;
          case 'servicebell':
            setServiceBell(user, account);
            break;
          case 'hubspot':
            setHubSpot(supportId);
            break;
          case 'intercome':
            this.setIntercom(supportId);
            break;
          case 'freshchat':
            // eslint-disable-next-line no-undef
            initiateCall(user);
            break;
          case 'chatra':
            setChatra(account, user, theme);
            break;
          default:
            break;
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        this.$noty.error(e.message);
      }
    },
    /**
     * @param {import('@/data/redirectRules').RedirectRule} redirectRule
     * @param {boolean} [toSaved=false]
     */
    _setRedirectTo (redirectRule, toSaved = false) {
      const savedPath = this.beforeRoute?.path;
      if (this.isRedirected) { return; }
      if (savedPath && toSaved && redirectRule.check(savedPath, this.isAdmin)) {
        this._redirect = this.beforeRoute;
        return;
      }
      const currentPath = this.$route.path;
      if (!redirectRule.check(currentPath, this.isAdmin)) {
        this._redirect = redirectRule.defaultRedirect;
      }
    },
    setIntercom (id = 'wtajtfk5') {
      // initialization Intercom
      this.$intercom.boot({
        app_id: id,
        name: this.$route.query.adminName || this.user.first_name,
        email: this.$route.query.admin || this.user.email,
        created_at: Math.floor(new Date(this.user.created_at).getTime() / 1000),
      });
    },

    async _setSelectedCredential (account) {
      if (this.credentialsList?.length) {
        let index = -1;
        const id = new URLSearchParams(window.location.search).get('selectedCred');
        if (id) {
          index = this.credentialsList.findIndex(cred => cred._id === id);
        }
        index = index > -1 ? index : 0;
        await this.$store.dispatch('credentials/SET_SELECTED_CREDENTIAL', this.credentialsList[index]);
        this.$socket.emit('change_credentials', {
          credentials: this.credentialsList[index]._id,
        });
        this.$socket.emit('join_prospects_room', {
          token: localStorage.getItem('token'),
          credentials: this.credentialsList[index]._id,
        });
        this.$socket.emit('join_ai_room', {
          token: localStorage.getItem('token'),
        });
      } else {
        await this.$store.dispatch('credentials/SET_SELECTED_CREDENTIAL', null);
        if (!account.is_billing_enabled) {
          this._setRedirectTo(redirectRules.dashboard);
          return false;
        }
        account.is_paid
          ? this._setRedirectTo(redirectRules.dashboard)
          : this._setRedirectTo(redirectRules.unpaid);
        return false;
      }
    },

    async _validateSubscription () {
      const response = await getSubscribe();
      if (response?.is_paid) {
        await this.$store.dispatch('account/SET_ACCOUNT', response);
        impactTrackScript(this.user, response, true);
      }
      return response;
    },

    async _afterBilling () {
      try {
        let response;
        let i = 0;
        let time = 15;
        do {
          i++;
          if (i >= 5) { break; }
          response = await this._validateSubscription();
          if (response.is_paid) { break; }
          await sleep(time * 1000);
          time = time * 2;
        } while (!response.is_paid);
        return response;
      } catch (e) {
        this.$noty.error(e.message);
      }
    },

    async _checkBilling (account) {
      let responseAccount = account;
      if (!account.is_paid && !account.payment_in_progress) {
        await this._setRedirectTo(redirectRules.unpaid);
      } else if (!account.is_paid && account.payment_in_progress) {
        await this._setRedirectTo(redirectRules.paymentInProgress);
        responseAccount = await this._afterBilling();
        if (!responseAccount.is_paid) {
          await this._setRedirectTo(redirectRules.unpaid);
        } else { this._setRedirectTo(redirectRules.dashboard, true); }
      } else if (account.is_paid && this.$route.query.redirect_from_stripe) {
        impactTrackScript(this.user, account, true);
      }
      return responseAccount;
    },

    async _initStore (account) {
      if (account.has_dialer) {
        await this.$store.dispatch('vox/SET_NUMBERS_LIST');
      }
      return Promise.all([
        this.$store.dispatch('account/SET_IS_ADMIN'),
        this.$store.dispatch('account/SET_SPACES_LIST'),
        this.$store.dispatch('credentials/SET_CREDENTIALS_LIST'),
        this.$store.dispatch('labels/SET_LABELS_LISTS'),
      ]);
    },

    async checkRouteFeature () {
      const feature = this.$route?.meta?.feature;
      if (!feature) return;

      const isFeatureAvailable = tierFeatures.statuses?.[feature]?.exist;
      if (isFeatureAvailable) return;

      await this.$router.push({ name: 'my-dashboard' });
    },

    async _initAccount (account, user) {
      account = await this._checkBilling(account);
      await this._initStore(account);
      await tierFeatures.initiate(account.tier, account);
      if (!isMobile) { this._setSupport(user, account, this.theme); }
      await this._setSelectedCredential(account);
      const promises = [];
      if (this.selectedCredential) {
        promises.push(this.$store.dispatch('integrations/SET_INTEGRATIONS_LIST', this.selectedCredential._id));
      }
      promises.push(this.$store.dispatch('workflows/SET_WORKFLOWS_LIST'));
      await Promise.all(promises);
      if (this.credentialsList?.length) {
        this._setRedirectTo(redirectRules.authorized, true);
        if (!this.space) {
          localStorage.setItem('loggedIn', 'true');
        }
      }
      this.$store.dispatch('account/SET_IS_WORKSPACE_ADMIN', account.admins.includes(user._id));
    },

    async _initEmptyAccount (user) {
      await this.$store.dispatch('account/SET_SPACES_LIST');
      if (user.brand !== 'yourleadtool') {
        await this.redirectToSetSpace();
      }
      if (!this.space) {
        localStorage.setItem('loggedIn', 'true');
      }
    },

    firstFetch: async function (account = null, user = null) {
      account = account || await getSpace();
      user = user || await getUser();
      await Promise.all([
        this.$store.dispatch('user/SET_USER', user),
        this.$store.dispatch('account/SET_ACCOUNT', account),
      ]);
      if (user?.config?.is_ghl_connected) {
        !this.isHorizontal && store.dispatch('view/UPDATE_VIEW', { horizontal: true, skipLocalStorage: true });
      }
      setBrandScripts(user, account);
      if (account) {
        await this._initAccount(account, user);
      } else {
        await this._initEmptyAccount(user);
      }
      if (this.$route.query.done === 'activation') {
        this.$noty.success('Your email was successfully confirmed');
      }
      await this.checkRouteFeature();
      if (this._redirect) {
        await this.$router.push(this._redirect);
      }
    },
  },
};
